import { joinClassNames } from "@common/lib/util"
import React, { forwardRef, ReactNode, CSSProperties, ForwardedRef } from "react"

interface StickyHeaderFooterProps {
  children?: ReactNode
  className?: string
}

interface HeaderProps {
  children?: ReactNode
  className?: string
}

interface MainProps {
  children?: ReactNode
  className?: string
  styles?: CSSProperties
}

interface FooterProps {
  children?: ReactNode
  className?: string
}

function StickyHeaderFooter({ children, className }: StickyHeaderFooterProps) {
  return (
    <div className={joinClassNames("h-full w-full flex flex-col", className)}>
      {children}
    </div>
  )
}

export function Header({ children, className = "" }: HeaderProps) {
  return <header className={className}>{children}</header>
}

export const Main = forwardRef(
  (
    { children, className = "", styles = {} }: MainProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        className={joinClassNames("overflow-y-auto flex-grow ", className)}
        style={styles}
        ref={ref}
      >
        {children}
      </div>
    )
  }
)

export function Footer({ children, className = "" }: FooterProps) {
  return <footer className={className}>{children}</footer>
}

StickyHeaderFooter.Header = Header
StickyHeaderFooter.Main = Main
StickyHeaderFooter.Footer = Footer

Main.displayName = "Main"
Header.displayName = "Header"
Footer.displayName = "Footer"

export default StickyHeaderFooter
