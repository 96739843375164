export function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage)
}

export function isAllSelected(data = [], selectedKeys = []) {
  return data.every((item) =>
    selectedKeys?.includes(typeof item === "string" ? item : item.key)
  )
}

export function getVisibleColumns(columnData = {}) {
  return Object.keys(columnData).filter((columnId) => {
    const data = columnData[columnId]
    return !data.invisible && columnId != "checkbox"
  })
}

/**
 * Returns an object mapping the indices of the selected rows to `true`.
 *
 * @param {Array<Object>} data - An array of row objects with an `id` property.
 * @param {Array<string>} selectedRowIds - An array of selected row IDs.
 * @returns {Object<string, boolean>} - An object where the keys are the indices of the selected rows in the `data` array, and the values are always `true`.
 *
 */
export function getRowSelections(data, selectedRowIds = []) {
  const rowSelections = {}
  data.forEach((row, index) => {
    if (selectedRowIds.includes(row.id)) {
      rowSelections[index] = true
    }
  })
  return rowSelections
}

export function getEditableTableState(state = {}) {
  const {
    columnSizing,
    // columnSizingInfo,
    sorting,
    columnFilters,
    columnPinning,
    columnOrder,
    columnVisibility,
    expanded,
  } = state

  return {
    columnSizing,
    // columnSizingInfo,
    sorting,
    columnFilters,
    columnPinning: {
      left:
        columnPinning.left?.filter(
          (key) => !["row-expand", "row-select"].includes(key)
        ) || [],
      right:
        columnPinning.right?.filter((key) => !["row-actions"].includes(key)) || [],
    },
    columnOrder,
    columnVisibility,
    expanded: expanded || {},
  }
}
