import React, { HTMLProps } from "react"
import { isFunction, joinClassNames } from "@common/lib/util"
import Label, { LabelProps } from "@common/forms/label"

type CheckboxStyledProps = Omit<HTMLProps<HTMLInputElement>, "label"> & {
  indeterminate?: boolean
  id: string
  isRadio?: boolean
  required?: boolean
  label?: LabelProps
}

const CheckboxStyled = ({
  indeterminate,
  label = {},
  required,
  isRadio = false,
  id,
  ...rest
}: CheckboxStyledProps) => {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate])

  return (
    <Label cursor="pointer" {...label} required={required} id={id}>
      <div className="flex items-center">
        <input
          {...rest}
          ref={ref}
          className={joinClassNames(
            "text-primary dark:text-muted bg-transparent ring-offset-0 focus:ring-0 focus:ring-offset-0  border-gray-500 dark:border-gray-700 focus:border-gray-500  dark:focus:border-gray-700 dark:checked:border-gray-700 dark:checked:focus:border-gray-700 ",
            isRadio
              ? "rounded-full radio-checkmark checked:bg-primary checked:focus:bg-primary checked:hover:bg-primary "
              : ""
          )}
          type="checkbox"
          id={id}
          required={required}
          readOnly={!isFunction(rest.onChange)}
        />
      </div>
    </Label>
  )
}

export default CheckboxStyled
