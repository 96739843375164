import SearchBar from "@common/searchBar"
import Loader from "@common/loader"
import StickyHeaderFooter, { Header, Main } from "@common/layouts/stickyHeaderFooter"
import InfiniteScroll from "react-infinite-scroller"
import { useState, useEffect, useRef } from "react"
import Skeleton from "@common/skeletons/skeleton"
import CheckboxStyled from "@common/forms/inputs/checkboxStyled"
import { isArraysEqual, isNullOrEmpty, localSearch } from "./lib/util"
import { isAllSelected } from "./table/util"
import { useKeyboardNavigation } from "@common/hooks/useKeyboardNavigation"
import { useInView } from "react-intersection-observer"

// Constants
const PAGESIZE = 10

// Types
interface ListSearchProps {
  list: Record<string, string>
  searchType?: "local" | "global"
  onSearch?: (input: string) => void
  renderListUnit: (
    key: string,
    isSelected: boolean,
    isActive: boolean
  ) => React.ReactNode
  onSelect?: (key: string) => void
  onMultiSelectList?: (selectedKeys: Array<string>) => void
  renderAdvancedFilters?: () => React.ReactNode
  emptyComponent?: React.ReactNode
  infiniteScroll?: boolean
  useWindow?: boolean
  initSelectedKeys?: Array<string>
  placeholder?: string
  searchContainerClasses?: string
  multiSelectList?: boolean
  autoFocus?: boolean
}

export default function ListSearch({
  list,
  searchType = "local",
  onMultiSelectList,
  renderAdvancedFilters,
  multiSelectList,
  emptyComponent,
  infiniteScroll = false,
  useWindow = false,
  renderListUnit,
  onSelect,
  onSearch,
  initSelectedKeys,
  placeholder = "Search",
  searchContainerClasses,
  autoFocus,
}: ListSearchProps) {
  const [input, setInput] = useState<string>("")
  const [selectedKeys, setSelectedKeys] = useState(initSelectedKeys)
  const [resultKeys, setResultKeys] = useState<string[]>(getKeys(list))
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [start, setStart] = useState<number>(0)
  const searchBoxRef = useRef(null)

  const { focusedIndex, handleKeyDown, focusedRef, overflowContainerRef } =
    useKeyboardNavigation({
      itemsLength: resultKeys?.length,
      onEnter: (index) => handleSelection(resultKeys?.[index]),
    })

  const isAllRowSelected = isAllSelected(resultKeys, selectedKeys)

  useEffect(() => {
    if (!focusedIndex && searchBoxRef.current && autoFocus)
      searchBoxRef.current?.focus()
  }, [focusedIndex, searchBoxRef])

  useEffect(() => {
    setSelectedKeys(initSelectedKeys)
  }, [initSelectedKeys])

  useEffect(() => {
    const keys = getKeys(list)
    if (!isArraysEqual(keys, resultKeys)) {
      setResultKeys(getKeys(list))
      setStart(0)
      setHasMore(true)
      if (input) search(input)
    }
  }, [list])

  useEffect(() => {
    if (searchType === "global") {
      const debounce = setTimeout(() => {
        search(input)
      }, 500)
      return () => clearTimeout(debounce)
    } else search(input)
  }, [input, searchType])

  const handleMultiSelection = (key: string) => {
    const currentSelectedKeys = selectedKeys || []
    const isSelected = currentSelectedKeys.includes(key)
    const rowKeys = isSelected
      ? currentSelectedKeys.filter((_key) => _key !== key)
      : [...currentSelectedKeys, key]
    setSelectedKeys(rowKeys)
    if (onMultiSelectList) onMultiSelectList(rowKeys)
  }

  const handleSelectAll = () => {
    let rowKeys: string[] = []
    if (!isAllRowSelected) rowKeys = resultKeys
    setSelectedKeys(rowKeys)
    if (onMultiSelectList) onMultiSelectList(rowKeys)
  }

  function renderListSection() {
    if (!resultKeys) return <Skeleton name="ListSkeleton" />
    if (resultKeys.length === 0)
      return (
        emptyComponent || (
          <div className="p-4 text-center font-normal text-sm text-secondary">
            No matches found.
          </div>
        )
      )

    if (infiniteScroll) {
      return (
        <InfiniteScroll
          pageStart={0}
          loadMore={(page) => getMoreList(page)}
          hasMore={hasMore}
          loader={<Loader />}
          useWindow={useWindow}
        >
          {renderList()}
        </InfiniteScroll>
      )
    }

    return renderList()
  }

  function renderList() {
    const displayedList = infiniteScroll
      ? resultKeys.slice(0, start + PAGESIZE)
      : resultKeys
    return displayedList.map((key, index) => renderListItem(key, index))
  }

  function renderListItem(key: string, index: number) {
    const isSelected = selectedKeys?.includes(key)
    const isActive = focusedIndex === index
    return (
      <div
        key={key}
        onClick={() => handleSelection(key)}
        className={isActive ? "bg-muted" : ""}
        ref={isActive ? focusedRef : undefined}
      >
        {renderListUnit(key, isSelected, isActive)}
      </div>
    )
  }

  function handleSelection(key: string) {
    if (multiSelectList) handleMultiSelection(key)
    else if (onSelect && key) onSelect(key)
  }

  function getMoreList(page: number) {
    if (hasMore) {
      const newStart = page * PAGESIZE
      setStart(newStart)
      if (resultKeys.length < newStart + PAGESIZE) setHasMore(false)
    }
  }

  function search(input: string) {
    if (searchType === "global" && onSearch) {
      onSearch(input)
      return
    }
    if (!input) {
      setResultKeys(getKeys(list))
      setStart(0)
      setHasMore(true)
      return
    }
    const searchResults = localSearch(input, list)
    setResultKeys(searchResults)
  }

  function getKeys(list: Record<string, string>): string[] {
    if (!list) return
    return Object.keys(list)
  }

  return (
    <StickyHeaderFooter>
      <Header>
        <div className="space-y-2">
          <div
            className={
              searchContainerClasses ||
              "flex border-b bg-background divide-x divide-border py-2"
            }
          >
            <SearchBar
              placeholder={placeholder}
              onChange={(input) => setInput(input)}
              handleKeyDown={handleKeyDown}
              ref={searchBoxRef}
            />
            {selectedKeys?.length > 0 && (
              <div className="text-sm my-auto pl-2">
                {selectedKeys.length} Selected
              </div>
            )}
          </div>
          {renderAdvancedFilters && renderAdvancedFilters()}
          {!isNullOrEmpty(resultKeys) && multiSelectList && (
            <div className="mx-3 py-2">
              <CheckboxStyled
                checked={isAllRowSelected}
                onChange={handleSelectAll}
                id="selectAllButton"
                label={{
                  label: !isNullOrEmpty(selectedKeys)
                    ? `${selectedKeys.length} Selected`
                    : `Select All`,
                  labelPosition: "right",
                }}
              />
            </div>
          )}
        </div>
      </Header>
      <Main ref={overflowContainerRef}>{renderListSection()} </Main>
    </StickyHeaderFooter>
  )
}
