import React, { ForwardedRef, forwardRef } from "react"
import { useState, useEffect, ReactNode } from "react"
import Button from "@common/buttons/button"
import Svg from "@common/svg"
import { isFunction } from "@common/lib/util"
type SearchBarProps = {
  onChange: (text: string) => void
  onSubmit?: (text?: string) => void
  renderHelpContent?: () => ReactNode
  value?: string
  onClearInput?: () => void
  placeholder?: string
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export default forwardRef(function SearchBar(
  {
    onChange,
    onSubmit,
    renderHelpContent,
    value,
    onClearInput,
    placeholder = "Search",
    handleKeyDown,
  }: SearchBarProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [text, setText] = useState(value)

  useEffect(() => {
    if (value !== text) setText(value)
  }, [value])

  const handleChange = (e: any) => {
    const text = e.target.value
    setText(text)
    if (onChange) onChange(text)
  }

  const handleClearInput = () => {
    setText("")
    onClearInput?.()
  }

  return (
    <div className="flex-1 flex">
      <form className="w-full flex md:ml-0" onSubmit={(e) => handleSubmit(e)}>
        <label htmlFor="search_field" className="sr-only">
          Search
        </label>
        <div className="relative w-full text-muted-foreground focus-within:text-primary">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <Svg name="search" classes="h-5 w-5" />
          </div>
          <input
            value={text}
            autoComplete="off"
            ref={ref}
            onChange={handleChange}
            id="search_field"
            className="bg-transparent  border-transparent focus:border-transparent focus:ring-transparent block w-full h-full pl-8 pr-3 py-2 rounded-md text-primary placeholder:text-muted-foreground  sm:text-sm"
            placeholder={placeholder}
            onKeyDown={(e) => {
              if (e.code == "Space") e.stopPropagation()
              else handleKeyDown?.(e)
            }}
            type={onClearInput ? "text" : "search"}
          />
          {value && onClearInput && (
            <div className="absolute inset-y-0 right-0 flex items-center cursor-pointer transform hover:scale-110 ">
              <Button
                variant="minimal"
                size="icon"
                type="button"
                onClick={handleClearInput}
              >
                <Svg name="cross" classes=" h-5 w-5" tooltipContent={undefined} />
              </Button>
            </div>
          )}
        </div>
      </form>
      {isFunction(renderHelpContent) ? renderHelpContent() : null}
    </div>
  )

  function handleSubmit(e: any) {
    e.preventDefault()
    if (onSubmit) onSubmit(text)
    // setText("")
  }
})
