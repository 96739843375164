import { cva, type VariantProps } from "class-variance-authority"
import { joinClassNames } from "@common/lib/util"
import Svg from "@common/svg"
import Button from "@common/buttons/button"
import { ReactNode, useState } from "react"

// Define cva variants for Alert component
const alertVariants = cva(" space-x-2 flex rounded-md  animate-slideDown relative", {
  variants: {
    variant: {
      success: "bg-green-500/10 text-secondary p-2 text-xs-r",
      warning: "bg-yellow-500/10 text-secondary p-2 text-xs-r",
      error: "bg-red-500/10 text-secondary p-2 text-xs-r",
      note: "bg-sky-50 dark:bg-sky-950/20 text-secondary p-2 text-xs-r",
      info: "bg-background text-foreground p-2 text-xs-r border",
      simpleError:
        "text-destructive text-xs border-none px-0 py-1 text-xs font-normal",
    },
  },
  defaultVariants: {
    variant: "note",
  },
})
const iconVariants = cva("", {
  variants: {
    variant: {
      success: "text-green-500 w-5 h-5",
      warning: "text-warning w-5 h-5",
      error: "text-destructive w-5 h-5",
      note: "text-sky-500 w-5 h-5",
      info: "text-secondary w-5 h-5",
      simpleError: "text-destructive w-3 h-3",
    },
  },
  defaultVariants: {
    variant: "note",
  },
})

// Alert component interface
type AlertProps = VariantProps<typeof alertVariants> &
  VariantProps<typeof iconVariants> & {
    children: ReactNode
    isDissmissable?: boolean
  }

// Component implementation
export default function Alert({
  children,
  variant,
  isDissmissable = false,
}: AlertProps) {
  const [closeAlert, setCloseAlert] = useState(false)

  return closeAlert ? null : (
    <div className={alertVariants({ variant })}>
      <div className="flex flex-col justify-start">
        <div
          className={joinClassNames(
            "py-1 shrink-0 flex  justify-center items-center"
          )}
        >
          <Svg
            name={
              variant == "error" || variant == "simpleError" || variant == "warning"
                ? "warning"
                : variant == "note" || variant == "info"
                  ? "info"
                  : variant == "success"
                    ? "check"
                    : "info"
            }
            strokeWidth={2}
            classes={iconVariants({ variant })}
          />
        </div>
      </div>
      <div className="flex items-center">{children}</div>
      {isDissmissable ? (
        <div className="-right-1 -top-1 absolute p-1">
          <Button onClick={() => setCloseAlert(true)} variant="minimal" size="xs">
            <Svg name="cross" classes="h-4 w-4 text-secondary" />
          </Button>
        </div>
      ) : null}
    </div>
  )
}

Alert.Layout = function AlertLayout({ children }: { children: ReactNode }) {
  return <div className="">{children}</div> // Add styles when needed
}

Alert.Header = function AlertHeader({
  children,
}: { children: ReactNode }) {
  return (
    <div className="text-base-r font-semibold text-primary">
      {children}
    </div>
  )
}
Alert.Content = function AlertContent({ children }: { children: ReactNode }) {
  return <div className="">{children}</div> // Add styles when needed
}
